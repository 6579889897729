import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLBCyc = GLTF & {
  nodes: any;
  materials: any;
};

export const Cyc = ({ ...props }: any) => {
  const hostUrl = process.env.PUBLIC_URL.replace(".", "");
  const cyc = useGLTF(`${hostUrl}assets/geo/reza-cyc.glb`) as GLBCyc;
  const cycEmissiveMap = useTexture(`${hostUrl}assets/bg_cyc_emissive4.png`);
  console.log("cyc material", cyc.nodes["bg_cyc"].material);
  cycEmissiveMap.flipY = false;
  return (
    <mesh
      geometry={cyc.nodes["bg_cyc"].geometry}
      position={cyc.nodes["bg_cyc"].position}
      scale={cyc.nodes["bg_cyc"].scale}
      rotation={cyc.nodes["bg_cyc"].rotation}
      receiveShadow={true}
    >
      <meshStandardMaterial
        emissiveMap={cycEmissiveMap}
        map={cycEmissiveMap}
        emissiveIntensity={1}
        needsUpdate={true}
      />
    </mesh>
  );
};
