import { useControls } from "leva";
import {
  MeshRefractionMaterial,
  MeshTransmissionMaterial,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

const MainGem = ({ ring, gem, hdrGems }: any) => {
  const hostUrl = process.env.PUBLIC_URL.replace(".", "");

  /*
  const mainGemConfig = useControls("main-gem", {
    useRefractiveMaterial: false,
    refMBounces: { value: 7, min: 0, max: 8, step: 1 },
    refMAberrationStrength: { value: 0, min: 0, max: 0.1, step: 0.01 },
    refMIor: { value: 1.7, min: 0, max: 10 },
    refMFresnel: { value: 1, min: 0, max: 1 },
    refMSapphireColor: "#16277c", // 0322bd
    refMEmeraldColor: "#1c5433",
    refMFastChroma: true,
    trTransmission: { value: 0.92, min: 0, max: 1, step: 0.05 },
    trThickness: { value: 0.08, min: 0, max: 1, step: 0.05 },
    trBacksideThickness: { value: 0, min: 0, max: 1, step: 0.05 },
    trRoughness: { value: 0, min: 0, max: 1, step: 0.05 },
    trChromaticAberration: { value: 0, min: 0, max: 1, step: 0.01 },
    trAnisotropy: { value: 0.15, min: 0, max: 1, step: 0.01 },
    trDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
    trDistortionScale: { value: 0, min: 0, max: 1, step: 0.01 },
    trTemporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
    trTransmissionSampler: true,
    trBackside: true,
    trSamples: { value: 6, min: 0, max: 10, step: 1 },
    trShapphireColor: "#0062df",
    trEmeraldColor: "#009b84",
  });
  */

  const mainGemConfig = {
    useRefractiveMaterial: false,
    refMBounces: 7,
    refMAberrationStrength: 0,
    refMIor: 1.7,
    refMFresnel: 1,
    refMSapphireColor: "#172fb0", // 0322bd // 0e3cdf // 16277c
    refMEmeraldColor: "#1c5433",
    refMFastChroma: true,
    trTransmission: 0.92,
    trThickness: 0.08,
    trBacksideThickness: 0,
    trRoughness: 0,
    trChromaticAberration: 0,
    trAnisotropy: 0.15,
    trDistortion: 0,
    trDistortionScale: 0,
    trTemporalDistortion: 0,
    trTransmissionSampler: true,
    trBackside: true,
    trSamples: 6,
    trShapphireColor: "#0062df",
    trEmeraldColor: "#009b84",
  };

  const [sColorMap, sRoughnessMap] = useLoader(TextureLoader, [
    `${hostUrl}assets/textures/stone-base4.png`,
    `${hostUrl}assets/textures/stone-roughness4.png`,
  ]);

  return (
    <mesh
      geometry={ring.nodes["Main_Gem_Idealized_w_0p1_Bevel_01"].geometry}
      position={ring.nodes["Main_Gem_Idealized_w_0p1_Bevel_01"].position}
      scale={ring.nodes["Main_Gem_Idealized_w_0p1_Bevel_01"].scale}
      rotation={ring.nodes["Main_Gem_Idealized_w_0p1_Bevel_01"].rotation}
      castShadow={true}
    >
      {mainGemConfig.useRefractiveMaterial ? (
        <MeshRefractionMaterial
          envMap={hdrGems}
          bounces={mainGemConfig.refMBounces}
          aberrationStrength={mainGemConfig.refMAberrationStrength}
          ior={mainGemConfig.refMIor}
          fresnel={mainGemConfig.refMFresnel}
          color={
            gem === "sapphire"
              ? mainGemConfig.refMSapphireColor
              : mainGemConfig.refMEmeraldColor
          }
          fastChroma={mainGemConfig.refMFastChroma}
          toneMapped={false}
        />
      ) : (
        <MeshTransmissionMaterial
          background={hdrGems}
          transmission={mainGemConfig.trTransmission}
          thickness={mainGemConfig.trThickness}
          backsideThickness={mainGemConfig.trBacksideThickness}
          roughness={mainGemConfig.trRoughness}
          chromaticAberration={mainGemConfig.trChromaticAberration}
          anisotropy={mainGemConfig.trAnisotropy}
          distortion={mainGemConfig.trDistortion}
          distortionScale={mainGemConfig.trDistortionScale}
          temporalDistortion={mainGemConfig.trTemporalDistortion}
          backside={mainGemConfig.trBackside}
          samples={mainGemConfig.trSamples}
          roughnessMap={sRoughnessMap}
          color={
            gem === "sapphire"
              ? mainGemConfig.trShapphireColor
              : mainGemConfig.trEmeraldColor
          }
        />
      )}
    </mesh>
  );
};

export default MainGem;
