import {
  MeshTransmissionMaterial,
  MeshRefractionMaterial,
} from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { useControls } from "leva";
import { TangentSpaceNormalMap, TextureLoader, Vector2 } from "three";
import MainGem from "./mainGem";

export const Ring2 = ({
  ring,
  material,
  gem,
  hdr,
  hdrGems,
  hdrDiamonds,
  useNormalMaps,
  ...props
}: any) => {
  const hostUrl = process.env.PUBLIC_URL.replace(".", "");

  const physicalConfig = {
    ringEnvMapIntensity: 0.5,
    normalScaleV1: 0.35,
    normalScaleV2: 0.35,
    transmission: 0,
    metalness: 1,
    thickness: 0,
    use16BitVs8Bit: true,
  };

  const [pColorMap, pMetallnessMap, pNormalMap, pRoughnessMap] = useLoader(
    TextureLoader,
    [
      `${hostUrl}assets/textures/platinum-base4.png`,
      `${hostUrl}assets/textures/platinum-metallic4.png`,
      physicalConfig.use16BitVs8Bit
        ? `${hostUrl}assets/textures/platinum-normal4-16.png`
        : `${hostUrl}assets/textures/platinum-normal4-8.png`,
      `${hostUrl}assets/textures/platinum-roughness4.png`,
    ]
  );

  const [gColorMap, gMetallnessMap, gNormalMap, gRoughnessMap] = useLoader(
    TextureLoader,
    [
      `${hostUrl}assets/textures/gold-base4.png`,
      `${hostUrl}assets/textures/gold-metallic4.png`,
      physicalConfig.use16BitVs8Bit
        ? `${hostUrl}assets/textures/gold-normal4-16.png`
        : `${hostUrl}assets/textures/gold-normal4-8.png`,
      `${hostUrl}assets/textures/gold-roughness4.png`,
    ]
  );

  pNormalMap.flipY = false;
  pColorMap.flipY = false;
  pMetallnessMap.flipY = false;
  pRoughnessMap.flipY = false;

  gNormalMap.flipY = false;
  gColorMap.flipY = false;
  gMetallnessMap.flipY = false;
  gRoughnessMap.flipY = false;

  const refractionConfig = {
    useRefractiveMaterial: true,
    refBounces: 3,
    refAberrationStrength: 0,
    refIor: 2.418,
    refFresnel: 1,
    refColor: "white",
    refFastChroma: true,
  };

  return (
    <group {...props}>
      <MainGem ring={ring} gem={gem} hdrGems={hdrGems} />
      <mesh
        geometry={ring.nodes["Baguettes_w_0p1_Bevel_01"].geometry}
        position={ring.nodes["Baguettes_w_0p1_Bevel_01"].position}
        scale={ring.nodes["Baguettes_w_0p1_Bevel_01"].scale}
        rotation={ring.nodes["Baguettes_w_0p1_Bevel_01"].rotation}
        castShadow
      >
        <MeshRefractionMaterial
          envMap={hdrDiamonds}
          bounces={refractionConfig.refBounces}
          aberrationStrength={refractionConfig.refAberrationStrength}
          ior={refractionConfig.refIor}
          fresnel={refractionConfig.refFresnel}
          color={refractionConfig.refColor}
          fastChroma={refractionConfig.refFastChroma}
          toneMapped={false}
        />
      </mesh>

      <mesh
        geometry={ring.nodes["Wave_Ring_Main_02"].geometry}
        position={ring.nodes["Wave_Ring_Main_02"].position}
        scale={ring.nodes["Wave_Ring_Main_02"].scale}
        rotation={ring.nodes["Wave_Ring_Main_02"].rotation}
        castShadow
      >
        {material === "platinum" && (
          <MeshTransmissionMaterial
            map={pColorMap}
            metalnessMap={pMetallnessMap}
            roughnessMap={pRoughnessMap}
            normalMap={pNormalMap}
            normalScale={
              new Vector2(
                physicalConfig.normalScaleV1,
                physicalConfig.normalScaleV2
              )
            }
            envMapIntensity={physicalConfig.ringEnvMapIntensity}
            distortionScale={0}
            temporalDistortion={0}
            transmission={physicalConfig.transmission}
            metalness={physicalConfig.metalness}
            normalMapType={TangentSpaceNormalMap}
            thickness={physicalConfig.thickness}
          />
        )}
        {material === "gold" && (
          <MeshTransmissionMaterial
            map={gColorMap}
            metalnessMap={gMetallnessMap}
            roughnessMap={gRoughnessMap}
            normalMap={gNormalMap}
            normalScale={
              new Vector2(
                physicalConfig.normalScaleV1,
                physicalConfig.normalScaleV2
              )
            }
            envMapIntensity={physicalConfig.ringEnvMapIntensity}
            distortionScale={0}
            temporalDistortion={0}
            transmission={physicalConfig.transmission}
            metalness={physicalConfig.metalness}
            normalMapType={TangentSpaceNormalMap}
            thickness={physicalConfig.thickness}
          />
        )}
      </mesh>

      {/*})}*/}
    </group>
  );
};
