import { Canvas, useLoader, useThree } from "@react-three/fiber";
import {
  MeshReflectorMaterial,
  OrbitControls,
  useGLTF,
  Environment,
  Stage,
  Bounds,
} from "@react-three/drei";
import { GLTF, RGBELoader } from "three-stdlib";
import { PMREMGenerator } from "three";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { Ref, useRef, useState } from "react";
import { Ring2 } from "./ring2";
import { Cyc } from "./cyc";
import { useControls } from "leva";

type GLBRing = GLTF & {
  nodes: any;
  materials: any;
};

const useEquirectangularHDR = (url: string) => {
  const { gl } = useThree();
  const pmremGenerator = new PMREMGenerator(gl);
  pmremGenerator.compileEquirectangularShader();

  const hdrEquirect = useLoader(RGBELoader, url);

  const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrEquirect);
  hdrEquirect.dispose();
  pmremGenerator.dispose();

  return hdrCubeRenderTarget.texture;
};

export default function RezaWaveRingSceneAlt() {
  const [material, setMaterial] = useState("platinum");
  const [gem, setGem] = useState("sapphire");

  const hostUrl = process.env.PUBLIC_URL.replace(".", "");
  const ring = useGLTF(
    `${hostUrl}assets/geo/reza-wave-ring3-nrm.glb`
  ) as GLBRing;
  const hdrTexture = useLoader(
    RGBELoader,
    `${hostUrl}assets/hdr/Ring_Lighting_Rig_v003.hdr`
  );

  //const spotLight1: Ref<any> | undefined = useRef();
  //useHelper(spotLight1, SpotLightHelper);

  /*
  const envConfig = useControls("env", {
    luminance: { value: 0.9, min: -2, max: 2, step: 0.01 },
    intensity: { value: 1, min: -2, max: 10, step: 0.01 },
    blur: { value: 0.2, min: -2, max: 2, step: 0.01 },
  });
  */

  const envConfig = {
    luminance: 0.9,
    intensity: 1,
    blur: 0.2,
  };

  /*
  const spotLightConfig = useControls("spotlight1", {
    s1x: { value: 0.51, min: -2, max: 2, step: 0.01 },
    s1y: { value: 0.28, min: -2, max: 2, step: 0.01 },
    s1z: { value: -0.5, min: -2, max: 2, step: 0.01 },
    s1intensity: { value: 0.6, min: 0, max: 10, step: 0.01 },
  });
  */

  const spotLightConfig = {
    s1x: 0.51,
    s1y: 0.28,
    s1z: -0.5,
    s1intensity: 0.6,
  };

  return (
    <div id="canvas-container">
      <Canvas camera={{ fov: 45, position: [0.3, 0.7, -0.95] }}>
        <color attach="background" args={["#000"]} />

        <OrbitControls makeDefault autoRotate={true} autoRotateSpeed={0.6} />

        <spotLight
          intensity={spotLightConfig.s1intensity}
          color={`#fff`}
          penumbra={0.5}
          decay={2}
          position={[
            spotLightConfig.s1x,
            spotLightConfig.s1y,
            spotLightConfig.s1z,
          ]}
          castShadow={true}
        />

        <Bounds fit clip observe damping={6} margin={1.55}>
          <Ring2
            ring={ring}
            material={material}
            gem={gem}
            position={[0, 0.12, 0]}
            hdr={hdrTexture}
            hdrGems={hdrTexture}
            hdrDiamonds={hdrTexture}
            useNormalMaps={true}
          ></Ring2>
          <EffectComposer>
            <Bloom
              luminanceThreshold={envConfig.luminance}
              intensity={envConfig.intensity}
              levels={9}
              mipmapBlur
            />
          </EffectComposer>
        </Bounds>
        <Cyc />
        <Environment
          blur={envConfig.blur}
          files={`${process.env.PUBLIC_URL.replace(
            ".",
            ""
          )}assets/hdr/Ring_Lighting_Rig_v003.hdr`}
        />
      </Canvas>
      <div className="ring-material">
        <div
          className="platinum"
          onClick={() => {
            setMaterial("platinum");
          }}
        >
          <span>platinum</span>
        </div>
        <div
          className="gold"
          onClick={() => {
            setMaterial("gold");
          }}
        >
          <span>gold</span>
        </div>
      </div>
      <div className="gem">
        <div
          className="sapphire"
          onClick={() => {
            setGem("sapphire");
          }}
        >
          <span>sapphire</span>
        </div>
        <div
          className="emerald"
          onClick={() => {
            setGem("emerald");
          }}
        >
          <span>emerald</span>
        </div>
      </div>
    </div>
  );
}
